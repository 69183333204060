import React from "react"

import { withSocial } from "./withSocial"
import { Wrapper, Button, Alternative } from "./SocialStyles"
import { Icon } from "../../Icon/Icon"
import { Errors, Error } from "../Layout/LayoutStyles"

export const Social = withSocial(
  ({ handleRedirect, errors, additionalAlternativeMethodText, additionalFacebookLoginText, additionalGoogleLoginText }): JSX.Element => (
    <>
      <Wrapper>
        <Button onClick={() => handleRedirect("facebook")}>
          <Icon name={"socialFacebook"} />
          <span>{additionalFacebookLoginText}</span>
        </Button>
        <Button onClick={() => handleRedirect("google")}>
          <Icon name={"socialGoogle"} />
          <span>{additionalGoogleLoginText}</span>
        </Button>
      </Wrapper>
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <Error key={index}>
              <p>{error}</p>
            </Error>
          ))}
        </Errors>
      )}
      <Alternative>
        <span>{additionalAlternativeMethodText}</span>
      </Alternative>
    </>
  )
)
