import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../hoc/withAuthentication"
import { Register as Page } from "../../components/Account/Register/Register"

import { useHotjar } from '../../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageAccountRegister {
      title
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = withoutAuthentication(({ data, ...props }): JSX.Element => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("register_page")
  
  return <Page {...props} {...data} />
})

export default Component
