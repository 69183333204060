import React from "react"

import { withRegisterForm } from "./withRegisterForm"
import {
  NameWrapper,
  CheckboxWrapper,
  TermsWrapper,
} from "./RegisterFormStyles"
import { Submit, Errors, Error } from "../../Layout/LayoutStyles"
import { Checkbox } from "../../../Checkbox/Checkbox"
import { RichText } from "../../../RichText/RichText"
import { TextField } from "../../../TextField/TextField"

export const RegisterForm = withRegisterForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    errors,
    additionalFirstNameLabel,
    additionalLastNameLabel,
    additionalEmailLabel,
    additionalPasswordLabel,
    additionalNewsletterText,
    additionalSubmitButton,
    additionalTermsAndConditions,
    additionalLoginText,
  }): JSX.Element => (
    <form onSubmit={handleSubmit}>
      <NameWrapper>
        <TextField
          name="firstName"
          type="text"
          value={data?.firstName}
          onChange={handleChange}
          label={additionalFirstNameLabel}
          required
          spacing
        />
        <TextField
          name="lastName"
          type="text"
          value={data?.lastName}
          onChange={handleChange}
          label={additionalLastNameLabel}
          required
          spacing
        />
      </NameWrapper>
      <TextField
        name="email"
        type="email"
        value={data?.email}
        onChange={handleChange}
        label={additionalEmailLabel}
        required
        spacing
      />
      <TextField
        name="password"
        type="password"
        value={data?.password}
        onChange={handleChange}
        label={additionalPasswordLabel}
        required
        spacing
      />
      <CheckboxWrapper>
        <Checkbox
          label={additionalNewsletterText}
          name="acceptsMarketing"
          checked={!!data?.acceptsMarketing}
          onChange={handleChange}
        />
      </CheckboxWrapper>
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <Error key={index}>
              <p>{error?.message}</p>
            </Error>
          ))}
        </Errors>
      )}
      <Submit size="primary" wide={"true"} type={`submit`} disabled={loading}>
        {additionalSubmitButton}
      </Submit>
      <TermsWrapper>
        <RichText>{additionalTermsAndConditions}</RichText>
      </TermsWrapper>
      <RichText>{additionalLoginText}</RichText>
    </form>
  ),
)
