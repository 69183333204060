import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCustomerContext, useCustomerSocialLogin } from "../../../hooks/useCustomer"

export const withSocial = Component => ({ name = "Social" }) => {
  const { loginCustomer } = useCustomerSocialLogin()
  const { socialLoginErrors } = useCustomerContext()

  const { login } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_SOCIAL_LOGIN {
      login: sanityPageAccountLogin {
        additionalAlternativeMethodText
        additionalFacebookLoginText
        additionalGoogleLoginText
      }
    }
  `)

  const { additionalAlternativeMethodText, additionalFacebookLoginText, additionalGoogleLoginText } = login || {}

  const handleRedirect = async providerName => {
    await loginCustomer(providerName)
  }

  Component.displayName = name
  return (
    <Component
      handleRedirect={handleRedirect}
      errors={socialLoginErrors}
      additionalAlternativeMethodText={additionalAlternativeMethodText}
      additionalFacebookLoginText={additionalFacebookLoginText}
      additionalGoogleLoginText={additionalGoogleLoginText}
    />
  )
}
