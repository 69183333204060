import tw, { styled } from "twin.macro"

export const NameWrapper = tw.div`
  grid grid-cols-1 md:grid-cols-2 gap-0-8
`

export const CheckboxWrapper = tw.div`
  mt-2 md:mt-1-6 mb-0-8
`

export const TermsWrapper = styled.div`
  ${tw`mb-3-6 md:mb-4`}
`
