import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCustomerRegister } from "../../../../hooks/useCustomer"
import { useHotjar } from '../../../../hooks/useHotjar'

export const withRegisterForm = Component => ({ name = "RegisterForm" }) => {
  const {
    createCustomer,
    data,
    setData,
    loading,
    errors,
  } = useCustomerRegister()

  const { trackHotjarEvent } = useHotjar()

  const { register } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_REGISTER {
      register: sanityPageAccountRegister {
        additionalFirstNameLabel
        additionalLastNameLabel
        additionalEmailLabel
        additionalPasswordLabel
        additionalNewsletterText
        additionalSubmitButton
        additionalTermsAndConditions: _rawAdditionalTermsAndConditions(
          resolveReferences: { maxDepth: 5 }
        )
        additionalLoginText: _rawAdditionalLoginText(
          resolveReferences: { maxDepth: 5 }
        )
      }
    }
  `)

  const {
    additionalFirstNameLabel,
    additionalLastNameLabel,
    additionalEmailLabel,
    additionalPasswordLabel,
    additionalNewsletterText,
    additionalSubmitButton,
    additionalTermsAndConditions,
    additionalLoginText,
  } = register || {}

  const handleSubmit = async event => {
    event.preventDefault()
    const filteredData = Object.entries(data).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {},
    )
    await createCustomer({
      ...filteredData,
    })
    trackHotjarEvent("customer_register_form")
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  };

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      additionalFirstNameLabel={additionalFirstNameLabel}
      additionalLastNameLabel={additionalLastNameLabel}
      additionalEmailLabel={additionalEmailLabel}
      additionalPasswordLabel={additionalPasswordLabel}
      additionalNewsletterText={additionalNewsletterText}
      additionalSubmitButton={additionalSubmitButton}
      additionalTermsAndConditions={additionalTermsAndConditions}
      additionalLoginText={additionalLoginText}
    />
  )
}
