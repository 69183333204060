import React from "react"

import { withRegister } from "./withRegister"
import { RegisterForm } from "./Form/RegisterForm"
import { Layout } from "../Layout/Layout"
import { Social } from "../Social/Social"

export const Register = withRegister(
  ({ title, isInitialized, image }): JSX.Element => (
    <Layout title={title} image={image}>
      {isInitialized && <Social />}
      <RegisterForm />
    </Layout>
  )
)
